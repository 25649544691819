import { CameraResultType, Plugins } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonAlert, IonContent, IonLoading, IonPage, useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import actions from "../../actions/auth";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";
import cartActions from "../../actions/cart";
import OneSignal from "onesignal-cordova-plugin";

const { Camera, Device } = Plugins;

const Profile: React.FC = (props: any) => {
  const [modal, setModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    defineCustomElements(window);
  }, []);

  useEffect(() => {
    if (!props.auth.user) props.history.push("/auth/login");
  }, [props.auth.user]);

  const updateAvatar = async (e) => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
    });

    const imageUrl: any = image.dataUrl;
    let blob = await fetch(imageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File(
            [blobFile],
            `image_${Math.floor(Math.random() * 10000000) + 1}.${image.format}`,
            { type: `image/${image.format}` }
          )
      );

    let data = new FormData();
    data.append("image", blob);

    axios
      .post("/user/profile/image_update", data, {
        headers: {
          accept: "application/json",
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then((response) => {
        dispatch(actions.update_avatar(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = async (e) => {
    localStorage.clear();
    dispatch(actions.logout());
  };

  const onDeleteAccount = async () => {
    axios
      .post("/delete_user", {
        id: user?.id
      })
      .then((response) => {
        setShowLoading(false);
        deleteTags();
        dispatch(cartActions.change_restaurant());
        dispatch(actions.logout());
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });

   
  }

  const deleteTags = async () => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      //Web

    } else {
      //iOS/Android
      OneSignal.deleteTags(['mb_no']);
    }
  }

  return (
    <IonPage>
      <Header
        name="Profile"
        click={() => props.history.goBack()}
        back={false}
      ></Header>

      <IonContent>
        <div className="h-flex mt-7 ">
          <div className="col-12 ">
            <div className="h-form-ordertime h-bgr-fff   ">
              <div className="h-flex j-center h-height-70px ">
                <div className="h-logo-chicko-chicken-center "></div>
              </div>
              <div className="h-flex h-flex__wrap pb-15 h-plr-15">
                <div className="h-max  h-line__bottom">
                  <div
                    className="h-flex a-center "
                    onClick={() => props.history.push(`/edit_profile`)}
                  >
                    <img
                      src={require("../../assets/img/icons/common/userIcon_profile.svg").default}
                      style={{ height: 18, width: 18 }}
                    />
                    <div className="h-form__profile">
                      <label className="h-bold">User Details</label>
                      <br />
                      <div className="h-input__profile">{user?.email}</div>
                    </div>
                  </div>
                </div>
                <div className="h-max  h-line__bottom">
                  <div
                    className="h-flex a-center "
                    onClick={() => props.history.push(`/credit_cards`)}
                  >
                    <img
                      src={require("../../assets/img/icons/common/cardIcon_profile.svg").default}
                      style={{ height: 18, width: 18 }}
                    />
                    <div className="h-form__profile">
                      <label className="h-bold">Credit / Debit Card</label>
                      <br />
                      <div className="h-input__profile">
                        {props.auth.default_card
                          ? "**** **** **** " +
                            props.auth.default_card.card_number
                          : "Add a Credit/Debit Card"}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="h-max  h-line__bottom"
                  href={"tel:" + props.info.restaurant_phone}
                >
                  <div className="h-flex a-center ">
                    <img
                      src={require("../../assets/img/icons/common/phoneIcon_profile.svg").default}
                      style={{ height: 18, width: 18 }}
                    />
                    <div className="h-form__profile">
                      <label className="h-bold" style={{ color: "#000" }}>
                        Contact Restaurant
                      </label>
                      <br />
                      <div className="h-input__profile">
                        {props.info.restaurant_phone}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="h-max  h-line__bottom">
                  <Link to="/choose_restaurant" style={{ color: "#000" }}>
                    <div className="h-flex a-center ">
                      <img
                        src={require("../../assets/img/icons/common/location_icon.svg").default}
                        style={{ height: 18, width: 18 }}
                      />
                      <div className="h-form__profile">
                        <label className="h-bold">Find Restaurant</label>
                        <br />

                        <div className="h-input__profile">
                          {props.info.restaurant_address +
                            ", " +
                            props.info.restaurant_street}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="h-max  h-line__bottom">
                  <Link to="/auth/change" style={{ color: "#000" }}>
                    <div className="h-flex a-center ">
                      <img
                        src={require("../../assets/img/icons/common/lock2.svg").default}
                        style={{ height: 18, width: 18 }}
                      />
                      <div className="h-form__profile">
                        <label className="h-bold">Change Password</label>
                        <br />
                        <div className="h-input__profile">
                          Click to change your password
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="h-max h-line__bottom"
                  style={{ color: "#000" }}
                  onClick={() => setShowAlert(true)}
                >
                  <div className="h-flex a-center ">
                    <img
                      src={require("../../assets/img/icons/common/delete.svg").default}
                      style={{ height: 18, width: 18 }}
                    />
                    <div className="h-form__profile">
                      <label className="h-bold">Delete account</label>
                      <br />
                        <div className="h-input__profile">
                          Click to delete your account
                        </div>
                    </div>
                  </div>
                </div>
                <div
                  className="h-max"
                  style={{ color: "#000" }}
                  onClick={() => {
                    deleteTags();
                    dispatch(cartActions.change_restaurant());
                    dispatch(actions.logout());
                  }}
                >
                  <div className="h-flex a-center ">
                    <img
                      src={require("../../assets/img/icons/common/signoutIcon_profile.svg").default}
                      style={{ height: 18, width: 18 }}
                    />
                    <div className="h-form__profile">
                      <label className="h-bold">Sign Out</label>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Container className="px-4">
          <Card className="shadow-sm mt-7">
            <Row className="justify-content-center mt-n6">
              <Col xs="5">
                <div className="card-profile-image position-relative">
                  <img
                    alt="..."
                    className="img-fluid rounded-circle shadow-lg"
                    src={
                      (user && user.avatar) ??
                      require("../../assets/img/icons/common/user.png")
                    }
                  />
                  <Button
                    onClick={updateAvatar}
                    color="primary"
                    size="sm"
                    type="button"
                    className="camera-btn position-absolute rounded-circle px-2 py-1"
                  >
                    <h5 className="m-0 p-0">
                      {" "}
                      <IonIcon
                        icon={camera}
                        className="align-text-top text-white"
                      />{" "}
                    </h5>
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-2 p-2">
              <Col xs="12">
                <Row className="m-0 p-2 border-bottom">
                  <Col xs="12">
                    <h6 className="w-100 text-left m-0 text-muted">
                      Full Name
                    </h6>
                  </Col>
                  <Col>
                    <h6 className="font-weight-bold w-100 text-muted m-0">
                      {user && user.name}
                    </h6>
                  </Col>
                </Row>
                <Row className="m-0 p-2 border-bottom">
                  <Col xs="12">
                    <h6 className="w-100 text-left m-0 text-muted">Email</h6>
                  </Col>
                  <Col>
                    <h6 className="font-weight-bold w-100 text-muted m-0">
                      {user && user.email}
                    </h6>
                  </Col>
                </Row>
                <Row className="m-0 p-2 border-0">
                  <Col xs="12">
                    <h6 className="w-100 text-left m-0 text-muted">Phone</h6>
                  </Col>
                  <Col>
                    <h6 className="font-weight-bold w-100 text-muted m-0">
                      {user && user.phone}
                    </h6>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Container>

        <Container className="px-4">
          <Card className="shadow-sm mt-2">
            <Row className="justify-content-center p-2">
              <Col xs="12">
                <Row className="m-0 p-2 border-bottom">
                  <Col>
                    <Link to="/dashboard/orders">
                      <h6 className="w-100 text-left m-0 text-muted">
                        <IonIcon icon={receipt} className="mr-4 text-light" />
                        My Order
                      </h6>
                    </Link>
                  </Col>
                </Row>
                <Row className="m-0 p-2 border-bottom">
                  <Col>
                    <Link to="/notifications">
                      <h6 className="w-100 text-left m-0 text-muted">
                        <IonIcon
                          icon={notifications}
                          className="mr-4 text-light"
                        />
                        Notifications
                      </h6>
                    </Link>
                  </Col>
                </Row>
                <Row className="m-0 p-2">
                  <Col>
                    <Link
                      to="/auth/logout"
                      onClick={() => {
                        localStorage.clear();
                        dispatch(actions.logout());
                      }}
                    >
                      <div>
                        <h6 className="font-weight-light w-100 text-left m-0 text-muted">
                          <IonIcon
                            icon={lockOpen}
                            className="mr-4 text-light"
                          />
                          Logout
                        </h6>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Container> */}

      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="ht-alert"
        header={"Confirm"}
        message={"Are your sure to delete your account?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            handler: (e) => {
              setShowAlert(false)
              setShowLoading(true);
              onDeleteAccount();
            },
          },
        ]}
      />

        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop={true}
          keyboard={true}
          style={{ top: "20%" }}
        >
          <ModalHeader toggle={toggle}>Update Info</ModalHeader>
          <ModalBody>
            <Input
              id="exampleFormControlInput1"
              placeholder="name@example.com"
              type="email"
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button block color="success" onClick={toggle}>
              Update
            </Button>
          </ModalFooter>
        </Modal>
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
