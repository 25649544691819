import { Plugins } from "@capacitor/core";
import axios from "../helpers/axiosInterceptor";
import OneSignal from 'onesignal-cordova-plugin';
import { externalConstants } from "./constants";
const { Device, PushNotifications, LocalNotifications } = Plugins;

const push_register = async (history) => {
  // Uncomment to set OneSignal device logging to VERBOSE  
  //OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId(externalConstants.ONESIGNAL_APP_ID);

  OneSignal.setNotificationWillShowInForegroundHandler(function (notificationReceivedEvent) {
    notificationReceivedEvent.complete(notificationReceivedEvent.getNotification());
  });

  OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

  // Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
  });

  // const info = await Device.getInfo();
  // if (info.platform === "web") return;

  // let auth, auth_token;
  // const storage = JSON.parse(localStorage.getItem("persist:storage") as any);

  // if (storage) {
  //   auth = storage.auth;
  //   auth_token = JSON.parse(auth).auth_token;
  // }

  // if (!auth_token) return;

  // PushNotifications.requestPermission().then((result) => {
  //   if (result.granted) {
  //     // Register with Apple / Google to receive push via APNS/FCM
  //     PushNotifications.register();
  //   } else {
  //     // Show some error
  //   }
  // });

  // PushNotifications.removeAllListeners();
  // LocalNotifications.removeAllListeners();

  // PushNotifications.addListener("registration", async (token) => {
  //   let data = JSON.stringify({
  //     device_token: { device_token: token.value },
  //   });
  //   axios
  //     .post(`/user/fcm_token`, { fcm_token: token.value })
  //     .then((response) => response.data.data)
  //     .then((res) => {
  //       localStorage.setItem("device_token", token.value);
  //     });
  // });

  // PushNotifications.addListener("registrationError", (error) => {
  //   // alert('Error on registration: ' + JSON.stringify(error));
  // });

  // PushNotifications.addListener(
  //   "pushNotificationReceived",
  //   (notification: any) => {
  //     LocalNotifications.schedule({
  //       notifications: [
  //         {
  //           id: Math.floor(Math.random() * 100) + 1,
  //           title: notification.title,
  //           body: notification.body,
  //           schedule: { at: new Date(Date.now() + 1000 * 2) },
  //           extra: notification,
  //         },
  //       ],
  //     });
  //     LocalNotifications.addListener(
  //       "localNotificationActionPerformed",
  //       (payload) => {
  //         history.push("/dashboard/orders");
  //       }
  //     );
  //   }
  // );

  // PushNotifications.addListener(
  //   "pushNotificationActionPerformed",
  //   (payload) => {
  //     history.push("/dashboard/orders");
  //   }
  // );
};

export { push_register };
