import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  PermissionType,
} from "@capacitor/core";
import {
  IonAlert,
  IonApp,
  IonRouterOutlet,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { alertActions } from "./actions/alert";
import actions from "./actions/info";
import OneSignal from 'onesignal-cordova-plugin';
// import { isMobile } from 'react-device-detect';
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
/* Theme variables */
// import './theme/variables.css';
import "./assets/vendor/nucleo/css/nucleo.css";
import AppUrlListener from "./components/Shared/AppUrlListener";
import FullPageLoader from "./components/Utilities/FullPageLoader";
import axios from "./helpers/axiosInterceptor";
import { fcmInitialization } from "./helpers/fcm-init";
import loadScript from "./helpers/loadScript";
import PrivateRoute from "./helpers/privateRoutes";

import RestrictedRoute from "./helpers/restrictedRoutes";
import ChangePassword from "./pages/auth/ChangePassword";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import RecoverPassword from "./pages/auth/RecoverPassword";
import Register from "./pages/auth/Register";
import Verify from "./pages/auth/Verify";
import EditProfile from "./pages/dashboard/EditProfile";
import Dashboard from "./pages/dashboard/Index";
import PaymentSuccess from "./pages/dashboard/PaymentSuccess";
import RewardDetail from "./pages/dashboard/RewardDetail";
import TopCategories from "./pages/dashboard/TopCategories";
import GiftCardCheckOut from "./pages/giftcards/GiftCardCheckOut";
import GiftCardDetail from "./pages/giftcards/GiftCardDetail";
import GiftCards from "./pages/giftcards/GiftCards";
import AddAddress from "./pages/locations/AddAddress";
import ChooseAddress from "./pages/locations/ChooseAddress";
import ChooseRestaurant from "./pages/locations/ChooseRestaurant";
import ChooseType from "./pages/locations/ChooseType";
import ManageAddress from "./pages/locations/ManageAddress";
import Cart from "./pages/shop/Cart";
import Food from "./pages/shop/Food";
import Notifications from "./pages/shop/Notifications";
import OrderDetail from "./pages/shop/OrderDetail";
import OrderTime from "./pages/shop/OrderTime";
import Payment from "./pages/shop/Payment";
import Store from "./pages/shop/Store";
import "./assets/css/home.css";
import CreditCard from "./pages/auth/CreditCard";
import EditCard from "./pages/auth/EditCard";
import AddCard from "./pages/auth/AddCard";
import PaymentCredit from "./pages/shop/PaymentCredit";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { externalConstants } from "./helpers/constants";
import { push_register } from "./helpers/push_notification_ionic";

const { Geolocation, Permissions, Device } = Plugins;

const App: React.FC = (props: any) => {
  const [showAlert, setShowAlert] = useState(false);

  if (props.auth.user?.stripe_id) {
    var promise = loadStripe(externalConstants.STRIPE_PUBLIC_KEY, {
      stripeAccount: props.auth.user?.stripe_id,
    });
  } else {
    var promise = loadStripe(externalConstants.STRIPE_PUBLIC_KEY);
  }

  const dispatch = useDispatch();

  const page_loader = useSelector(
    (state: RootStateOrAny) => state.alert.page_loader
  );

  const isMobile = window.innerWidth <= 756;

  useEffect(() => {
    if (props.auth.user?.stripe_id) {
      promise = loadStripe(externalConstants.STRIPE_PUBLIC_KEY, {
        stripeAccount: props.auth.user?.stripe_id,
      });
    } else {
      promise = loadStripe(externalConstants.STRIPE_PUBLIC_KEY);
    }
  }, [props.auth.user?.stripe_id]);

  const pushNotifications = async () => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      //Web FCM
      // fcmInitialization();
    } else {
      //iOS/Android
      push_register(props.history);
    }
  };

  useEffect(() => {
    pushNotifications();
  }, []);

  const sendTags = async (id) => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      //Web
    } else {
      //iOS/Android
      OneSignal.sendTags({
        mb_no: id
      });
    }
  }

  useEffect(() => {
    if(props.auth?.user?.id){
      sendTags(props.auth?.user?.id)
    }
  }, [props.auth?.user?.id]);



  useEffect(() => {
    // dispatch(alertActions.page_loader(true));
    // axios.get("/settings/map").then((res) => {
    //   const apiKey = res.data.google_map_api_key;
    //   loadScript(
    //     "https://maps.googleapis.com/maps/api/js?libraries=places&key=" +
    //       apiKey,
    //     "googleMap",
    //     () => {
    //       dispatch(actions.set_api_key(apiKey));
    //       dispatch(alertActions.page_loader(false));
    //       getCurrentPosition(apiKey);
    //     }
    //   );
    // });
    getCurrentPosition("AIzaSyBEu8TqrV0zONJRNnfi9gTso9Rzwg7aNbk");
  }, []);

  const getCurrentPosition = async (apiKey) => {
    Geolocation.getCurrentPosition({
      enableHighAccuracy: isPlatform("ios") ? false : true,
    })
      .then((coordinates) => {
        // dispatch(alertActions.page_loader(true));
        const lat: number = coordinates.coords.latitude;
        const lng: number = coordinates.coords.longitude;
        reverseGeoCoder(lat, lng, apiKey);
      })
      .catch((e) => {
        getCurrentPosition("AIzaSyBEu8TqrV0zONJRNnfi9gTso9Rzwg7aNbk");
      });
  };

  const reverseGeoCoder = async (lat: number, lng: number, apiKey) => {
    dispatch(actions.set_location({ lat, lng }));
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=" +
        apiKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
        const results = responseJson.results;
        setTimeout(() => {
          dispatch(alertActions.page_loader(false));
        }, 1500);

        results.some((c) => {
          let foundAddress = false;
          switch (c.types[0]) {
            case "street_address":
              dispatch(actions.set_address(c.formatted_address));
              foundAddress = true;
              break;
            case "route":
            case "locality":
              dispatch(actions.set_address(c.formatted_address));
              foundAddress = true;
              break;
            default:
              break;
          }

          return foundAddress;
        });
      })
      .catch((e) => {
        dispatch(alertActions.page_loader(false));
      });
  };

  return (
    <Elements stripe={promise}>
      <IonApp className={isMobile ? "my-ionic-mobile" : "my-ionic-web"}>
        {page_loader && <FullPageLoader />}
        <IonToast
          isOpen={props.error != ""}
          onDidDismiss={() => {
            dispatch(alertActions.error(""));
            setShowAlert(false);
          }}
          message={props.error}
          duration={3500}
          color="primary"
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Alert"}
          message={
            "You need to allow location permission to use this app correctly"
          }
          buttons={["OK"]}
        />
        <IonReactRouter>
          {/* {isMobile ? ( */}
          <AppUrlListener></AppUrlListener>
          <IonRouterOutlet>
            <RestrictedRoute path="/" exact={true} />
            <Route path={`/auth/login`} component={Login} exact={true} />
            <Route path={`/auth/register`} component={Register} exact={true} />
            <Route path={`/auth/verify`} component={Verify} exact={true} />
            <Route
              path={`/auth/change`}
              component={ChangePassword}
              exact={true}
            />
            <Route
              path={`/auth/recover`}
              component={RecoverPassword}
              exact={true}
            />
            <Route path={`/auth/logout`} component={Logout} exact={true} />
            <Route path={`/choosetype`} component={ChooseType} exact={true} />
            <Route path={`/food/:res/:id`} component={Food} />
            <Route
              path={`/choose_restaurant`}
              component={ChooseRestaurant}
              exact={true}
            />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/shop/:id" component={Store} />
            <Route path="/top_category/:id" component={TopCategories} />
            <Route path="/order/:id" component={OrderDetail} />
            <Route path={`/cart`} component={Cart} />
            <Route path="/payment" component={Payment} />
            <Route path="/edit_profile" component={EditProfile} />
            <Route path="/payment_success" component={PaymentSuccess} />
            <Route path="/gift_cards/:id" component={GiftCardDetail} />
            <Route
              path="/gift_cards_checkout"
              component={GiftCardCheckOut}
              exact={true}
            />

            <Route
              path="/reward_detail"
              component={RewardDetail}
              exact={true}
            />
            <Route path="/address" component={ManageAddress} />
            <Route path="/choose-address" component={ChooseAddress} />
            <Route path="/add-address" component={AddAddress} />

            <Route path="/order_time" component={OrderTime} />
            <Route path="/notifications" component={Notifications} />

            <Route path="/credit_card/edit" component={EditCard} />
            <Route path="/credit_card/add" component={AddCard} />
            <Route path="/credit_cards" component={CreditCard} />
            <Route path="/payment_credit" component={PaymentCredit} />
          </IonRouterOutlet>

          {/*  : (
           <IonRouterOutlet>
             <Route path="/" component={Intro} exact={true} />
             <Redirect from="*" to="/" />
           </IonRouterOutlet>
         )} */}
        </IonReactRouter>
      </IonApp>
    </Elements>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.alert.message,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
