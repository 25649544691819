import {
  IonAlert,
  IonContent,
  IonInput,
  IonPage,
  useIonViewDidLeave,
} from "@ionic/react";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/auth";
import Header from "../../components/Shared/Header";

const AddAddress: React.FC = (props: any) => {
  const [searchAddress, setSearchAddress] = useState("");
  const [detail, setDetail] = useState("");
  const [center, setCenter] = useState<any>({
    lat: 46.22331676255192,
    lng: -74.08265954083463,
  });
  const [mapAddress, setMapAddress] = React.useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const createMapOptions = function (maps) {
    return {
      disableDefaultUI: true,
    };
  };
  useIonViewDidLeave(() => {
    setDetail("");
  });
  useEffect(() => {
    if (
      props.history?.location?.state.address &&
      props.history?.location?.state.coordinate
    ) {
      setCenter(props.history?.location?.state.coordinate);
      setSearchAddress(props.history?.location?.state.address);
    }
    return () => { };
  }, []);

  const reverseGeoCoder = async (lat: number, lng: number) => {
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      lat +
      "," +
      lng +
      "&key=" +
      "AIzaSyBEu8TqrV0zONJRNnfi9gTso9Rzwg7aNbk"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        const results = responseJson.results;
        results.some((c) => {
          let foundAddress = false;
          switch (c.types[0]) {
            case "street_address":
              setMapAddress(c.formatted_address);
              setCenter(c.geometry.location);
              foundAddress = true;
              break;
            case "route":
            case "locality":
              setMapAddress(c.formatted_address);
              setCenter(c.geometry.location);
              foundAddress = true;
              break;
            default:
              break;
          }
          return foundAddress;
        });
      });
  };
  const addAddress = () => {
    // if (searchAddress.trim() === "" || detail.trim() === "") return;

    if (detail == "") {
      setShowAlert(true);
    } else {
      const { lat, lng } = center;

      axios
        .post(`/user/address`, {
          full_address: mapAddress ? mapAddress : searchAddress,
          label: detail,
          lat: lat,
          lng: lng,
        })
        .then((res) => {
          const data = res.data;
          dispatch(alertActions.page_loader(true));
          dispatch(
            actions.set_address({
              id: data.id,
              label: data.label,
              full_address: data.street,
            })
          );

          setTimeout(() => {
            if (props.auth.user) {
              props.history.push("/address");
            } else {
              props.history.push("/dashboard/home");
            }

            // if (props.history?.location?.state && props.history?.location?.state?.redirectTo)
            //   props.history.push(props.history?.location?.state.redirectTo);
            // else props.history.go(-2);
          }, 1000);
        })
        .catch((err) => {
          dispatch(alertActions.error("There are errors, please try again!"));
          dispatch(alertActions.page_loader(false));
        });
    }
  };

  const submitAddress = () => { };

  return (
    <IonPage>
      <Header name="Add Address" click={() => props.history.goBack()}></Header>
      <IonContent>
        <div style={{ height: "calc(100% - 190px)" }}>
          <GoogleMapReact
            options={createMapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyB92Eq4j0kZGonFQ_Xw_o5vk_ufMHyXxjI",
            }}
            defaultCenter={center}
            center={center}
            defaultZoom={16}
            onDragEnd={(map) => {
              const lat = map.getCenter().lat();
              const lng = map.getCenter().lng();
              setCenter({ lat, lng });
              reverseGeoCoder(lat, lng);
            }}
            onChildClick={(e) => { }}
            onClick={({ x, y, lat, lng, event }) => { }}
          >
            {/* <Marker
              lat={props.history?.location?.state?.coordinate?.lat}
              lng={props.history?.location?.state?.coordinate?.lng}
            >

            </Marker> */}
          </GoogleMapReact>
        </div>
        <Modal>
          <div className="list-restaurant">
            <Container hideBottom={props.hideBottom} className="prevent">
              <div className="same first prevent">
                <img
                  src={require("../../assets/img/icons/common/map_position.svg").default}
                  alt=""
                />
              </div>
              <div className="mid prevent">
                <div className="title">Current Marked Location</div>
                {/* <div className="address">Enter room, floor,...</div> */}
                <IonInput
                  value={mapAddress ? mapAddress : searchAddress}
                  placeholder="30 Vice Regent Boulevard, Etobicoke"
                  onIonChange={(e) => { }}
                  className="add-address"
                ></IonInput>

                <div></div>
              </div>
            </Container>
            <Container hideBottom={true} className="prevent">
              <div className="same first prevent">
                <img
                  src={require("../../assets/img/icons/common/edit.svg").default}
                  alt=""
                />
              </div>
              <div className="mid prevent">
                <div className="title">Complete Address</div>
                <input
                  value={detail}
                  placeholder="Enter room, floor..."
                  onChange={(e) => {
                    setDetail((e.target as HTMLInputElement).value);
                  }}
                  className="add-address"
                  style={{ backgroundColor: "#fff", border: "none" }}
                ></input>
              </div>
            </Container>
            <div
              className="h-btn-alws-bottom button-inner"
              style={{ padding: "13px 0 0 0" }}
            >
              <button
                className="btn-border-rdu-30"
                color="primary"
                onClick={() => addAddress()}
              >
                ADD ADDRESS
              </button>
            </div>
          </div>
        </Modal>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Alert"}
          message={"Please enter complete address!"}
          buttons={["OK"]}
        />
      </IonContent>
      <img
        style={{
          position: "absolute",
          top: "calc(50% - 115px)",
          left: "calc(50% - 15px)",
          zIndex: 5,
        }}
        src={require("../../assets/img/icons/common/local_red.svg").default}
        alt=""
      />
    </IonPage>
  );
};
const Marker = styled.div`
  height: 20px;
  width: 20px;
  background: #045ade;
  border-radius: 10px;
  border: 2px solid #fff;
  position: relative;
  .info {
    width: calc(100vw - 40px);
    border-radius: 3px;
    padding: 10px;
    left: calc(-50vw + 18px);
    position: absolute;
    top: -140px;
    &:after {
      content: "";
      position: absolute;
      border-top: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      bottom: 9px;
      left: 50%;
      z-index: 2;
    }
  }
`;
const Modal = styled.div`
  padding-top: 10px;
  height: 235px;
  width: 100%;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: absolute;
  bottom: 0;
  .list-restaurant {
    width: 100%;
    padding: 0 20px;
    overflow-y: scroll;
    overflow-x: auto;
  }
`;

const Container = styled.div`
  display: flex;
  border-bottom: ${(props) =>
    props.hideBottom ? "none" : "1px solid #eeeeee"};
  padding: 15px 0 10px;
  .same {
    display: flex;
    align-items: center;

    &.first {
      width: 40px;
    }
    &.last {
      justify-content: center;
      width: 80px;
      .order {
        background: #045ade;
        color: #fff;
        border-radius: 5px;
        padding: 3px 12px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.5px;
      }
    }
  }
  .mid {
    width: calc(100% - 40px);
    .title {
      font-weight: bold;
      font-size: 15px;
    }
    .address {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      color: #707070;
    }
    .distance {
      font-size: 15px;
      color: #707070;
    }
  }
`;
const mapStateToProps = (state) => {
  return {
    location: state.info,
    auth: state.auth,
    api_key: state.info.api_key,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
