import {
  IonContent,
  IonIcon,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { cart } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import styled from "styled-components";
import Addons from "../../components/MenuCards/addons";
import Food from "../../components/Shared/Food";
import Container from "../../components/Shared/Container";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import actions from "../../actions/cart";

const Detail: React.FC = (props: any) => {
  const [detailsRes, setDetailsRes] = useState({} as any);
  const [dishesList, setDishesList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [category, setCategory] = useState({} as any);
  //Selectors
  const getDishState = (store) => store.cart.dishes;

  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("ionBackButton", (ev: any) => {
      ev.detail.register(999, () => {
        if (props.cart.addons && props.cart.addons?.addons_dish?.length > 0) {
          dispatch(actions.addons_hide());
        } else {
          props.history.goBack();
        }
      });
    });
  }, [props.cart.addons, props.cart.addons?.addons_dish]);

  useIonViewDidEnter(() => {
    dispatch(actions.changed());
  });

  const getAllDishes = (store) => {
    const dishes = getDishState(store) ?? [];
    let total = 0,
      addons_cost = 0,
      count = 0;

    dishes.map((dish) => {
      count += dish.count;
      dish.addons_dish.map((d) => {
        d.addons.map((a) => (addons_cost += parseInt(a.price)));
      });
      return (total += dish.count * dish.price + addons_cost);
    });
    return { count, total };
  };
  const basket_restaurant_id = useSelector(
    (state: RootStateOrAny) => state.cart.restaurant_id
  );
  const basket = useSelector((state: RootStateOrAny) => getAllDishes(state));

  const currency_symbol = useSelector(
    (state: RootStateOrAny) => state.auth.currency_symbol
  );

  // Get index

  useEffect(() => {
    setLoaded(!props.alert.page_loading);
  }, [props.alert.page_loading]);

  useEffect(() => {
    props.categories.map((val, idx) => {
      if (val.id == props.match.params.id) {
        setCategory(val);
      }
    });
  }, []);

  useEffect(() => {
    if (props.restaurant) {
      setDetailsRes(props.restaurant);
      setDishesList(props.restaurant.dishes);
      // setTimeout(() => {
      //   setLoaded(true);
      // }, 500);
    }
  }, [props.restaurant]);
  let addons;
  addons = dishesList?.map((val: any, idx) => {
    if (val.dish_category_id == props.match.params.id) {
      return (
        <Food
          key={idx}
          src={val.image}
          name={val.name}
          description={val.description}
          price={val.restaurant_dish.price}
          addon={val}
          restaurant_dish={val.restaurant_dish}
          restaurant_id={props.restaurantID}
          hide_image={true}
        ></Food>
      );
    }
  });

  return (
    <IonPage>
      <IonContent style={{ position: "relative" }}>
        <Header>
          <img
            className="blue-back-safe-are"
            src={require("../../assets/img/icons/common/back-blue.svg").default}
            alt=""
            style={{ height: 60 }}
            onClick={() => props.history.goBack()}
          />
          {/* <img
          src={require("../../assets/img/icons/common/cart-blue.svg")}
          alt=""
          style={{ height: 60 }}
        /> */}
        </Header>
        <Container>
          <Banner className="banner border no-repeat" background={"url(" + category?.image + ")"}></Banner>
          <Banner className="banner banner-blur" background={"url(" + category?.image + ")"}></Banner>
          {loaded ? (
            <>
              {addons}
              {loaded &&
                detailsRes.id === basket_restaurant_id &&
                basket.count > 0 && (
                  <Link
                    className="row fixed-bottom w-100 p-3 m-0 text-white"
                    to="/cart"
                    style={{ backgroundColor: "#045ADE" }}
                    replace={true}
                  >
                    <Col xs="8" className="p-0">
                      {basket.count} Item |{" "}
                      {currency_symbol ? currency_symbol : "$"}
                      {Math.round(Number(basket.total) * 100) / 100}
                    </Col>
                    <Col className="text-right p-0">
                      Checkout
                      <IonIcon
                        icon={cart}
                        className="ml-2 align-text-top text-white"
                      />
                    </Col>
                  </Link>
                )}
              <Addons
                name={detailsRes.name}
                history={props.history}
                free={false}
              />
              <div style={{ height: 40 }}></div>
            </>
          ) : (
            <>
              <GeneralSkeletonText />
              <GeneralSkeletonText />
            </>
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
};

const Banner = styled.div`
  height: calc(100vw * 0.75);
  background: ${(props) => props.background};
  background-size: cover;
  margin: -10px -20px 20px -20px;
  background-position: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 15px 10px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.5)
  );
`;

const mapStateToProps = (state) => {
  return {
    state: state.auth,
    restaurantID: state.info.restaurant_id,
    restaurant: state.info.restaurant,
    categories: state.info.categories,
    alert: state.alert,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
